/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { HttpLoaderFactory } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CommonModule } from "@angular/common";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { GridsterModule } from "angular-gridster2";
import { SurveysModule } from "./app/surveys/surveys.module";
import { EmailArchiveModule } from "./app/email-archive/email-archive.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ConfirmModalModule } from "./app/shared-ui/confirm-modal/confirm-modal.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CoreModule } from "./app/core/core.module";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { LanguageInterceptor } from "./app/core/helpers/language.interceptor";
import { HttpErrorInterceptor } from "./app/core/helpers/http-error-interceprot";
import { BasicAuthInterceptor } from "./app/core/helpers/basic-auth.interceptor";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from "@angular/common/http";
import { DeactivateGuard } from "./app/core/helpers/deactivate.guard";

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            AppRoutingModule,
            CoreModule,
            ReactiveFormsModule,
            NgbModule,
            FormsModule,
            ConfirmModalModule,
            NgxSpinnerModule,
            EmailArchiveModule,
            SurveysModule,
            GridsterModule,
            ToastrModule.forRoot({ enableHtml: true }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
            CommonModule,
            FontAwesomeModule
        ),
        DeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ],
}).catch((err) => console.error(err));
