
import { Component, OnInit, } from '@angular/core';
import { SpinnerService } from "../../../../app/core/services/spinner.service"
import { NgClass } from '@angular/common';
@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class SpinnerComponent implements OnInit {

  constructor(
    public spinner: SpinnerService,
  ) { }

  ngOnInit() {
  }

}

