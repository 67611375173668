import { NgModule } from "@angular/core";
import { ConfirmModalComponent } from "./components/confirm-modal.component";
import { ConfirmModalService } from "../../core/helpers/confirm-modal.service";

@NgModule({
    imports: [ConfirmModalComponent],
    exports: [ConfirmModalComponent],
    providers: [ConfirmModalService],
})
export class ConfirmModalModule {}
