import { Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { SurveyApiService } from 'src/app/core/api/survey-api.service';
import { Survey } from 'src/app/core/models/survey';
import { Location, NgFor, NgIf, NgClass } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SurveyQuestion } from 'src/app/core/models/survey-question';
import { AnswerTypeEnum } from 'src/app/core/models/enum/answer-type-enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../../../core/services/spinner.service';
import { SurveyAnswerChoice } from 'src/app/core/models/survey-answer-choice';
import { SurveyAnswerChoiceApiService } from 'src/app/core/api/survey-answer-choice-api.service';
import { TranslatePipe } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-add-survey',
    templateUrl: './add-survey.component.html',
    styleUrls: ['./add-survey.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, NgClass, TranslatePipe]
})
export class AddSurveyComponent implements OnInit, OnDestroy {


  // @ViewChild('draggable') private draggableElement:ElementRef;
  @ViewChildren('draggable') draggableElement: QueryList<ElementRef>;

  @ViewChildren('surveyContainer', { read: ElementRef })
  private surveyContainer: QueryList<ElementRef>;

  surveyQuestions: SurveyQuestion[] = [];
  sortedSurveyQuestion: SurveyQuestion[] = [];
  questionValue: string = '';
  title: string = '';
  externalTitle: string = '';
  description: string = '';
  answerLimit: string = '';
  isOpen;
  answerTypeValues: string[] = [];
  commentAdded: boolean = false;

  public editQuestionRef: SurveyQuestion;
  public editQuestionNewText: string;
  public surveyId: number = 0;
  public editMode: boolean = false;
  private id: number;
  public sortedIDs: number[] = [];
  public selectedElementIndex: number
  public questionsOrder: number = 1;

  constructor(
    private surveyApiService: SurveyApiService,
    private surveyChoiceApiService: SurveyAnswerChoiceApiService,
    private location: Location,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private renderer: Renderer2
  ) { }

  ngOnDestroy(): void {
    if ((this.description == "" || null) && (this.title == "" || null) && (this.answerLimit == "" || null)) {
      this.surveyApiService.deleteSurvey(this.id).subscribe();
    }
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    if (this.id) {
      this.spinner.show();
      this.editMode = true;
      this.surveyApiService.get(this.id.toString()).subscribe(result => {
        this.title = result.title;
        this.externalTitle = result.externalTitle
        this.description = result.description;
        this.answerLimit = result.answerLimit == null ? '' : result.answerLimit.toString();
        this.isOpen = result.isOpen == true ? '1' : '0';
        result.surveyQuestions.forEach(question => {
          this.surveyQuestions.push(question);
        });
        this.surveyQuestions = result.surveyQuestions;
        this.questionsOrder = this.surveyQuestions.length + 1
        this.spinner.hide();
        this.surveyQuestions.sort((a, b) => {
          return a.order - b.order
        })
      });
    }
    for (const value in AnswerTypeEnum) {
      if (typeof AnswerTypeEnum[value] === 'string') {
        this.answerTypeValues.push(value);
      }
    }
  }

  private patchSurveyQuestion(question: SurveyQuestion) {
    this.surveyApiService.patchSurveyQuestion(question).subscribe();
  }

  public addQuestion() {
    var value = this.questionValue.trim();
    if (value.length != 0) {
      var surveyQuestion = new SurveyQuestion();
      surveyQuestion.question = value;
      surveyQuestion.answerType = 0;
      surveyQuestion.surveyId = this.id;
      surveyQuestion.comment = this.commentAdded;
      surveyQuestion.order = this.questionsOrder++
      this.surveyApiService.postSurveyQuestion(surveyQuestion).subscribe(result => {
        surveyQuestion.id = result.id;
        this.surveyQuestions.push(surveyQuestion);
      });
    }
    this.questionValue = "";
    this.commentAdded = false;
  }

  public toggleComment(question: SurveyQuestion) {
    question.comment = !question.comment;
    this.saveChoiceChanges(question);
  }

  public editQuestion(question: SurveyQuestion) {
    this.editQuestionRef = question;
    this.editQuestionNewText = question.question;
  }

  public removeQuestion(question: SurveyQuestion) {
    this.surveyApiService.deleteQuestion(question.id).subscribe(result => {
      this.surveyQuestions.splice(this.surveyQuestions.indexOf(question), 1);
      this.questionsOrder = this.questionsOrder - 1
    });
  }

  public addChoice(question: SurveyQuestion) {
    if (!question.surveyAnswerChoices) {
      question.surveyAnswerChoices = new Array<SurveyAnswerChoice>();
    }
    var choice = new SurveyAnswerChoice()
    choice.surveyQuestionId = question.id;
    this.surveyChoiceApiService.postChoice(choice).subscribe(result => {
      question.surveyAnswerChoices.push(result)
    })
  }

  public updateChoice(choice: SurveyAnswerChoice) {
    this.surveyChoiceApiService.patchChoice(choice).subscribe();
  }

  public deleteChoice(item: SurveyQuestion, choice: SurveyAnswerChoice) {
    this.surveyChoiceApiService.deleteChoice(choice.id).subscribe(result => {
      var choiceIndex = item.surveyAnswerChoices.findIndex(element => element == choice);
      item.surveyAnswerChoices.splice(choiceIndex, 1)
    })
  }

  public applyQuestionTextChange() {
    this.editQuestionRef.question = this.editQuestionNewText;
    this.surveyApiService.patchSurveyQuestion(this.editQuestionRef).subscribe(
      () => {
        this.toastr.success("Opdateret survey spørgsmål");
        this.editQuestionRef = null;
      },
      err => {
        console.error(err);
      }
    );
  }

  public saveChoiceChanges(item: SurveyQuestion) {
    this.surveyApiService.patchSurveyQuestion(item).subscribe(result => {
      console.log(result)
    })
  }

  public goBack() {
    if ((this.description == "" || null) && (this.title == "" || null) && (this.answerLimit == "" || null)) {
      this.surveyApiService.deleteSurvey(this.id).subscribe(result => {
        this.router.navigate(['surveys']);
      });
    }
    else {
      this.router.navigate(['surveys']);
    }
  }

  onSubmit(value) {
    var survey = new Survey();
    survey.title = value.title;
    survey.externalTitle = value.externalTitle
    survey.description = value.description;
    survey.answerLimit = parseInt(value.answerLimit);
    survey.isOpen = this.isOpen == '1' ? true : false;
    survey.surveyQuestions = this.surveyQuestions;
    if (this.editMode) {
      survey.id = this.id;
    }
    if (this.editMode) {
      this.surveyApiService.patchSurvey(survey).subscribe(result => {
        this.location.back();
      });
    }
    else {
      this.surveyApiService.postSurvey(survey).subscribe(result => {
        this.location.back();
      });
    }
  }

  onAnswerTypeChanged(event, item: SurveyQuestion) {
    var selectedValue = +event.target.value;
    item.answerType = selectedValue;
    this.patchSurveyQuestion(item);
  }



  getAnswerTypeText(value) {
    switch (value) {
      case '0': {
        return '1 - 5 range'
      }
      case '1': {
        return 'Fri tekst'
      }
      case '2': {
        return 'Ja/Nej'
      }
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  onDragStart(event: DragEvent, index) {

    this.selectedElementIndex = index
    this.renderer.addClass(this.draggableElement.toArray()[index].nativeElement, 'dragging');
  }

  onDragEnd(event: DragEvent, index) {
    let oldSort = [...this.surveyQuestions];
    this.sortedSurveyQuestion = [];
    this.sortedIDs = [];
    this.renderer.removeClass(this.draggableElement.toArray()[index].nativeElement, 'dragging')
    this.surveyContainer.toArray().map(elem => {
      let childrenArray = elem.nativeElement

      Array.from(childrenArray.children).forEach((child: any, i) => {
        let element = child
        this.sortedIDs.push(Number(element.dataset.surveyid))
      })
    })
    this.sortedIDs.forEach((id, i) => {
      let found = false;
      this.surveyQuestions.filter(survey => {
        if (!found && survey.id === id) {
          this.questionsOrder = i + 1
          survey.order = this.questionsOrder
          this.sortedSurveyQuestion.push(survey)
          found = true;
          return false;
        } else {
          return true
        }
      })
    })
    this.surveyQuestions = this.sortedSurveyQuestion
    this.questionsOrder = this.surveyQuestions.length + 1
    this.surveyQuestions.forEach((survey, i) => {
      oldSort[i] !== survey ? this.patchSurveyQuestion(survey) : null
    })
  }
  onDragOver(event: DragEvent) {
    const afterElement: any = this.getDragAfterElement(event.clientY)
    if (afterElement === null) {
      this.renderer.appendChild(this.surveyContainer.toArray()[0].nativeElement, this.draggableElement.toArray()[this.selectedElementIndex].nativeElement)
    } else {
      this.renderer.insertBefore(this.surveyContainer.toArray()[0].nativeElement, this.draggableElement.toArray()[this.selectedElementIndex].nativeElement, afterElement.element)
    }

  }
  public getDragAfterElement(y) {
    const draggableElements = Array.from(document.querySelectorAll('.draggable:not(.dragging)'))
    return draggableElements.reduce((closest, child) => {
      const box = child.getBoundingClientRect()

      const offset = y - box.top - box.height / 2
      if (offset < 0 && offset > closest.offset) {
        return { offset: offset, element: child }
      } else {
        return closest
      }
    }, { offset: Number.NEGATIVE_INFINITY })
  }

}
