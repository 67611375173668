import { Component, OnInit } from '@angular/core';
import { User } from './core/models/user';
import { AuthenticationService } from './core/services/authentiation.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from './core/services/lang.service';
import { NotificationComponent } from './notification/notification.component';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './dashboard/components/spinner/spinner.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [SpinnerComponent, RouterOutlet, NotificationComponent]
})
export class AppComponent implements OnInit {
  public currentUser: User;
  private title = 'people-it';
  supportLanguages = ['da', 'en'];

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private langService: LangService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('da');
    console.log("THIS IS THE ENVIRONMENT: ", environment.environmentName);
  }

  ngOnInit(): void {
    let currentLang = this.langService.getSavedLang();
    this.translateService.use(currentLang);
    console.log('current lang', currentLang);
    console.log(currentLang);
  }
}
