import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-yes-no-question',
    templateUrl: './yes-no-question.component.html',
    styleUrls: ['./yes-no-question.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class YesNoQuestionComponent implements OnInit {

  @Input() index: number;
  @Output() answerChanged = new EventEmitter<string>()

  public surveyAnswer: string;
  constructor() { }

  ngOnInit() {
  }

  public emitValue(event) {
    this.answerChanged.emit(event.target.value)
  }

}
