import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmailArchivePageComponent } from "./pages/email-archive-page/email-archive-page.component";
import { ShowEmailComponent } from "./components/show-email/show-email.component";
import { ModalService } from "../core/modal/modal.service";
import { EmailArchiveRoutingModule } from "./email-archive-routing.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, EmailArchiveRoutingModule, TranslateModule, EmailArchivePageComponent, ShowEmailComponent],
    exports: [EmailArchivePageComponent],
    providers: [ModalService],
})
export class EmailArchiveModule {}
