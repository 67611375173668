import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SurveyApiService } from "src/app/core/api/survey-api.service";
import { Survey } from "src/app/core/models/survey";
import { of } from "rxjs";
import { ConfirmModalService } from "src/app/core/helpers/confirm-modal.service";
import { SpinnerService } from "../../../core/services/spinner.service";
import { Toast, ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedSurvey } from "src/app/core/models/shared-survey";
import { TranslatePipe } from "@ngx-translate/core";
import { NgClass, NgFor } from "@angular/common";

@Component({
    selector: "app-surveys",
    templateUrl: "./surveys.component.html",
    styleUrls: ["./surveys.component.scss"],
    standalone: true,
    imports: [
        NgClass,
        NgFor,
        TranslatePipe,
    ],
})
export class SurveysComponent implements OnInit {
    surveys: Survey[] = [];
    public sortDescending = false;
    public contextSharedSurvey = new SharedSurvey();
    public url = `${window.location.origin}/surveys/shared?uid=`;

    constructor(
        private router: Router,
        private surveyApiService: SurveyApiService,
        private confirmModalService: ConfirmModalService,
        private spinner: SpinnerService,
        private toastr: ToastrService,
        private modal: NgbModal
    ) {}

    ngOnInit() {
        this.loadData();
    }

    public addSurvey() {
        let survey = new Survey();
        survey.title = "";
        survey.externalTitle = "";
        survey.description = "";
        survey.isOpen = true;
        this.surveyApiService.postSurvey(survey).subscribe((result) => {
            this.router.navigate(["surveys/edit/" + result.id]);
        });
    }

    public sort() {
        if (this.sortDescending) {
            this.surveys = this.surveys.sort((a, b) =>
                b.title.localeCompare(a.title)
            );
        } else {
            this.surveys = this.surveys.sort((a, b) =>
                a.title.localeCompare(b.title)
            );
        }
        this.sortDescending = !this.sortDescending;
    }

    private loadData() {
        this.surveyApiService.getSurveys().subscribe((result) => {
            this.surveys = result;
            this.sortDescending = false;
        });
    }

    public showSurvey(survey: Survey) {
        this.router.navigate([`surveys/result/${survey.id}`]);
    }

    //TODO add edit and delete option
    public edit(survey: Survey) {
        this.router.navigate([`surveys/edit/${survey.id}`]);
    }

    public delete(survey: Survey) {
        this.confirmModalService
            .confirm(
                "Slet af survey",
                "Er du sikker på at du vil slette denne survey?"
            )
            .subscribe((_) => {
                this.spinner.show();
                this.surveyApiService.deleteSurvey(survey.id).subscribe((_) => {
                    this.spinner.hide();
                    this.toastr.success("Survey er nu blevet slettet");
                    this.loadData();
                });
            });
    }

    public share(survey: Survey, shareModal) {
        this.surveyApiService
            .getSharedSurveyURL(survey.id)
            .subscribe((result) => {
                if (!result) {
                    var sharedSurvey = new SharedSurvey();
                    sharedSurvey.surveyId = survey.id;
                    this.surveyApiService
                        .putSharedSurvey(sharedSurvey)
                        .subscribe((result) => {
                            this.contextSharedSurvey = result;
                        });
                } else this.contextSharedSurvey = result;
                this.modal
                    .open(shareModal, { ariaLabelledBy: "Share modal" })
                    .result.then(
                        (result) => {},
                        () => {
                            this.contextSharedSurvey = new SharedSurvey();
                        }
                    );
            });
    }

    public copyGeneratedUrlEvent(value) {
        value.select();
        document.execCommand("copy");
        value.setSelectionRange(0, 0);
        this.toastr.info("Link er nu blevet kopiret til udklipsholder", "", {
            timeOut: 2000,
        });
    }
}
