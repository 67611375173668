import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyQuestion } from 'src/app/core/models/survey-question';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-stars-question',
    templateUrl: './stars-question.component.html',
    styleUrls: ['./stars-question.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class StarsQuestionComponent implements OnInit {
  @Input() public item: SurveyQuestion
  @Output() answerChanged = new EventEmitter<string>()

  public surveyAnswer: string;
  constructor() { }

  ngOnInit() {
  }

  public emitValue(event) {
    this.answerChanged.emit(event.target.value)
  }

}
