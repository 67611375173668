
import { NgModule } from "@angular/core";
import { SurveysRoutingModule } from './surveys-routing.module';
import { SurveysComponent } from './components/surveys/surveys.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AddSurveyComponent } from './components/add-survey/add-survey.component';
import { SurveyResultComponent } from './components/survey-result/survey-result.component';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
    CommonModule,
    SurveysRoutingModule,
    FormsModule,
    TranslateModule,
    SurveysComponent,
    AddSurveyComponent,
    SurveyResultComponent
]
})

export class SurveysModule { }