import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AuthenticationService } from "../../../core/services/authentiation.service";
import { first } from "rxjs/operators";
import { User } from "src/app/core/models/user";
import { UserRoleEnum } from "src/app/core/models/enum/user-role-enum";
import { ToastrService } from "ngx-toastr";
import { SpinnerService } from "../../../core/services/spinner.service";
import { HttpClient } from "@angular/common/http";
import { LinkedInService } from "src/app/core/services/linked-in.service";
import { UserApiService } from "src/app/core/api/user-api.service";
import { LangService } from "src/app/core/services/lang.service";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
    selector: "app-login-page",
    templateUrl: "./login-page.component.html",
    styleUrls: ["./login-page.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        TranslatePipe,
    ],
})
export class LoginPageComponent implements OnInit {
    loginForm: UntypedFormGroup;
    displayTwoFactor = false;
    submitted = false;
    error = "";
    public currentLang;

    constructor(
        private userApiService: UserApiService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private spinner: SpinnerService,
        private httpClient: HttpClient,
        private linkedInService: LinkedInService,
        private langService: LangService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(["/"]);
        }
        this.currentLang = this.langService.getSavedLang();
    }

    public ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ["", Validators.required],
            password: ["", Validators.required],
            twoFactorCode: [""],
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    public onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.toastr.error("Ikke alle felter er udfyldt korrekt");
            return;
        }
        this.spinner.show();
        this.authenticationService
            .login(
                this.f.email.value,
                this.f.password.value,
                this.f.twoFactorCode.value != ""
                    ? this.f.twoFactorCode.value
                    : null
            )
            .pipe(first())
            .subscribe(
                (user) => {
                    if (user.requireTwoFactor) {
                        this.displayTwoFactor = true;
                        this.spinner.hide();
                        this.toastr.info(
                            "Du har fået sendt en Two-Factor kode"
                        );
                        return;
                    }
                    this.router.navigate([this.getRedirect(user)]);
                    if (user.firstLogin)
                        this.authenticationService.firstLogin = true;
                    this.router.navigate([this.getRedirect(user)]);
                },
                (error) => {
                    this.spinner.hide();
                    this.error = error;
                }
            );
    }

    public loginLinkedIn() {
        var redirectUri = window.location.origin + "/linkedIn-auth";
        var scopes = "r_emailaddress r_liteprofile".replace(/ /g, "%20");
        this.linkedInService.linkedInLogin(
            redirectUri,
            scopes,
            this.getRandomInt(1000, 9999)
        );
    }

    private getRedirect(user: User): string {
        const returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (returnUrl) {
            return returnUrl;
        }
        return this.getLandingRoute(user.userRoleEnumDto);
    }

    private getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    private getLandingRoute(role: UserRoleEnum) {
        switch (role) {
            case UserRoleEnum.Admin:
                return "/";
            case UserRoleEnum.Profile:
                return "/edit-my-profile-page";
            case UserRoleEnum.CustomerProfile:
                return "/customer";
        }
    }
    public useLang(value) {
        this.langService.changeLang(value);
    }
}
