import { Component, OnInit, ViewChild } from "@angular/core";
import { TodoService } from "src/app/core/services/todo.service";
import { UserApiService } from "../core/api/user-api.service";
import { LangService } from "../core/services/lang.service";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from '../core/services/authentiation.service';
import { UserRoleEnum } from '../core/models/enum/user-role-enum';
import { ModalService } from "../core/modal/modal.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";
import { ToastrService } from "ngx-toastr";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChangePassword } from "../core/models/change-password";
import { SpinnerService } from "../core/services/spinner.service";
import { TranslateService, TranslatePipe } from "@ngx-translate/core";
import { MainMenuComponent } from "../main-menu/components/main-menu/main-menu.component";
import { TodoListFullComponent } from "../dashboard/components/todo-list-full/todo-list-full.component";
import { NgIf } from "@angular/common";
import { RouterLink, RouterOutlet } from "@angular/router";

@Component({
    selector: "app-main-customer-profile",
    templateUrl: "./main-customer-profile.component.html",
    styleUrls: ["./main-customer-profile.component.scss"],
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        TodoListFullComponent,
        FormsModule,
        ReactiveFormsModule,
        MainMenuComponent,
        RouterOutlet,
        TranslatePipe,
    ],
})
export class MainCustomerProfileComponent implements OnInit {
    @ViewChild("changePasswordModal", { static: false }) changePasswordModal: any;
    modalRef: NgbModalRef;
    changePasswordForm: UntypedFormGroup;
    securityGuid: string;
    public isTodoShown: boolean = false;
    public showConfirmation: boolean = false;
    public currentLang;
    constructor(
        private todoService: TodoService,
        private langService: LangService,
        private userApiService: UserApiService,
        private modalService: ModalService,
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private toastr: ToastrService,
        private spinner: SpinnerService,
        private translateService: TranslateService

    ) {
        this.currentLang = this.langService.getSavedLang();
    }

    ngOnInit() {
      this.todoService.setTodosVisibility$.subscribe((x) => {
          this.isTodoShown = x;
      });
        

      const user = this.authenticationService.currentUserValue;
        if (user.userRoleEnumDto === UserRoleEnum.CustomerProfile && user.firstLogin) {
            this.initiateChangePasswordProcess();
        }
        this.buildForm();
    }

    buildForm(): void {
        this.changePasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            passwordConfirm: ['', Validators.required],
        });
    }

    initiateChangePasswordProcess(): void {
        this.userApiService.requestChangePassword().subscribe({
            next: (response) => {
                this.securityGuid = response.token;
                this.openChangePasswordModal();
            },
            error: (error) => {
                console.error("Failed to initiate password change: ", error);
                this.translateService.get('toastrMsgs.error').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
            }
        });
    }

    openChangePasswordModal(): void {
        this.modalRef = this.modalService.open(this.changePasswordModal, {
            size: 'lg',
            centered: true,
        });
    }

  onSubmit(): void {
      
      if (this.changePasswordForm.invalid || this.changePasswordForm.value.password !== this.changePasswordForm.value.passwordConfirm) {
          this.toastr.error("Password matcher ikke, eller formen er udfyldt forkert");
          return;
      }

      let changePasswordData = new ChangePassword();
      changePasswordData.password = this.changePasswordForm.value.password;
      changePasswordData.securityGuid = this.securityGuid;

      this.spinner.show();
      this.userApiService.changePassword(changePasswordData).subscribe({
          next: () => {
              this.showConfirmation = true;
              this.translateService.get('changePassword.changedToastr').subscribe((res: string) => {
                this.toastr.success(res);
            });
              this.modalRef.close();
              this.spinner.hide();
          },
          error: (error) => {
            this.translateService.get('toastrMsgs.error').subscribe((res: string) => {
              this.toastr.error(res);
            });
              console.error("Failed to change password: " + error.message);
              this.spinner.hide();
          }
      });
  }

    public toggleTodoList(value: boolean) {
        this.todoService.toggleTodoList(value);
    }

    public useLang(value) {
        this.langService.changeLang(value);
        this.userApiService.patchLanguage(value).subscribe();
    }
}
