import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EmailLogListResult } from '../models/email-log-list-result';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailLogApiService {
    private apiBaseUrl = `${environment.apiUrl}EmailLog`;

    constructor(private httpClient: HttpClient) { }

    public GetEmails(page:number, itemsPerPage:number): Observable<EmailLogListResult> {
        const params = new HttpParams()
        .set("page", ""+page)
        .set("limit", ""+itemsPerPage);
        return this.httpClient.get<EmailLogListResult>(`${this.apiBaseUrl}/getEmails`, { params: params });
    }
}
