import { Component, OnInit } from "@angular/core";
import { UserApiService } from "../core/api/user-api.service";
import { ToastrService } from "ngx-toastr";
import { SpinnerService } from "../core/services/spinner.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ChangePassword } from "../core/models/change-password";
import { LangService } from "src/app/core/services/lang.service";
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { NgIf } from "@angular/common";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"],
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        TranslatePipe,
    ],
})
export class ChangePasswordComponent implements OnInit {
    public changePassword: ChangePassword;
    public changePasswordForm: UntypedFormGroup;
    public passwordConfirm: string;
    public showConfirmation = false;
    public currentLang;

    constructor(
        private userApiService: UserApiService,
        private toastr: ToastrService,
        private spinner: SpinnerService,
        private formBuilder: UntypedFormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private langService: LangService,
        private translateService: TranslateService
    ) {
        this.currentLang = this.langService.getSavedLang();
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const securityGuid = params['token'];
            console.log(securityGuid);
            if (!securityGuid) {
                this.translateService.get('toastrMsgs.error').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                this.router.navigate(["/"]);
            }
        this.buildForm();
    });
    }
    
    private buildForm() {
        this.changePasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            passwordConfirm: ['', Validators.required],
        });
    }

            onSubmit(): void {
                if (this.changePasswordForm.invalid) {
                    this.translateService.get('toastrMsgs.notAllFieldsFilledCorrectly').subscribe((res: string) => {
                        this.toastr.error(res);
                        });
                    return;
                }
                if (this.changePasswordForm.value.password !== this.changePasswordForm.value.passwordConfirm) {
                    this.translateService.get('changePassword.notEqual').subscribe((res: string) => {
                    this.toastr.error(res);
                    });
                    return;
                }


                const changePasswordData = new ChangePassword();
                changePasswordData.password = this.changePasswordForm.value.password;
                changePasswordData.securityGuid = this.activatedRoute.snapshot.queryParams['token'];

                this.spinner.show();
                this.userApiService.changePassword(changePasswordData).subscribe({
                    next: () => {
                        this.translateService.get('changePassword.changedToastr').subscribe((res: string) => {
                            this.toastr.success(res);
                        });
                        this.router.navigate(['/login']);
                        this.spinner.hide();
                    },
                    error: (error) => {
                        this.toastr.error('Failed to change password: ' + error.message);
                        this.spinner.hide();
                    }
                });
            }

        
        

    public useLang(value) {
        this.langService.changeLang(value);
    }
}
