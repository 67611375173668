import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { LoginPageComponent } from "./login/pages/login-page/login-page.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BasicAuthInterceptor } from "./core/helpers/basic-auth.interceptor";
import { MainMenuComponent } from "./main-menu/components/main-menu/main-menu.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalModule } from "./shared-ui/confirm-modal/confirm-modal.module";
import { MainComponent } from "./main/main.component";
import { MainAdminComponent } from "./main-admin/main-admin.component";
import { MainProfileComponent } from "./main-profile/main-profile.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
// import { ForgotPasswordComponent } from "./forgot-password/Pages/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";

import { EmailArchiveModule } from "./email-archive/email-archive.module";
import { HttpErrorInterceptor } from "./core/helpers/http-error-interceprot";
import { DeactivateGuard } from "./core/helpers/deactivate.guard";
import { GridsterModule } from "angular-gridster2";
import { SurveyAttemptComponent } from "./surveys/components/survey-attempt/survey-attempt.component";
import { LinkedInAuthComponent } from "./linked-in-auth/linked-in-auth.component";
import { FacebookAuthComponent } from "./facebook-auth/facebook-auth.component";
import { LinkedInSocialAuthComponent } from "./linked-in-social-auth/linked-in-social-auth.component";
import { NotificationComponent } from "./notification/notification.component";
import { SpinnerComponent } from "./dashboard/components/spinner/spinner.component";

import { MainCustomerProfileComponent } from "./main-customer-profile/main-customer-profile.component";
import { NotificationMenuComponent } from "./main-menu/components/notification-menu/notification-menu.component";
import { NavigationMenuComponent } from "./main-menu/components/navigation-menu/navigation-menu.component";
import { TaskManagmentModalComponent } from "./tasks/components/task-managment-modal/task-managment-modal.component";
import { TodoListFullComponent } from "src/app/dashboard/components/todo-list-full/todo-list-full.component";
import { ActivitiesAddModalComponent } from "src/app/activities/components/activities-add-modal/activities-add-modal.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LangComponent } from "./core/lang/lang.component";
import { LanguageInterceptor } from "./core/helpers/language.interceptor";
import { CommonModule } from "@angular/common";

import { SurveysModule } from "./surveys/surveys.module";
import { YesNoQuestionComponent } from "./surveys/components/yes-no-question/yes-no-question.component";
import { StarsQuestionComponent } from "./surveys/components/stars-question/stars-question.component";
import { MultipleChoiceQuestionComponent } from "./surveys/components/multiple-choice-question/multiple-choice-question.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


