import { Component, OnInit } from "@angular/core";
import { DashboardApiService } from "src/app/core/api/dashboard-api.service";
import { TodoTasksDto } from "./models/todo-task-dto";
import { TodoTask } from "./factory/todo-task";
import { TodoTaskFactory } from "./factory/todo-task-factory";
import { ActivatedRoute, Router } from "@angular/router";
import { TaskTypeEnum } from "src/app/core/models/enum/task-type-enum";
import { ToastrService } from "ngx-toastr";
import { TodoTaskStatusEnum } from "src/app/core/models/enum/todo-task-status-enum";
import { ModalService } from "src/app/core/modal/modal.service";
import { NgbDate, NgbDateParserFormatter, NgbModalOptions, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { ActivitiesAddModalComponent } from "src/app/activities/components/activities-add-modal/activities-add-modal.component";
import { AuthenticationService } from "src/app/core/services/authentiation.service";
import { User } from "src/app/core/models/user";
import { UserRoleEnum } from "src/app/core/models/enum/user-role-enum";
import { convertToDate, convertToNgbDate } from "src/app/core/Util/util";
import { NgbDateCustomParserFormatter } from "src/app/core/Util/ngb-date-parser-customer-formatter";
import { Activity } from "src/app/core/models/activity";
import { ProfileDetailsService } from "src/app/profile-details/services/profile-details.service";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { ActivityApiService } from "src/app/core/api/activity-api.service";
import { TranslatePipe } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { NgIf, NgClass, NgFor, DatePipe } from "@angular/common";

@Component({
    selector: "app-todo-list-full",
    templateUrl: "./todo-list-full.component.html",
    styleUrls: ["./todo-list-full.component.scss"],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        FormsModule,
        NgbInputDatepicker,
        DatePipe,
        TranslatePipe,
    ],
})
export class TodoListFullComponent implements OnInit {
    private tasksDto: TodoTasksDto[] = [];
    private finishedTasksDto: TodoTasksDto[] = [];
    public tasks: TodoTask[] = [];
    public finishedTasks: TodoTask[] = [];

    public isTaskTypeNameAsc: boolean = false;
    public isDescriptionAsc: boolean = false;
    public isDateAsc: boolean = false;
    public isConsultNameAsc: boolean = false;
    public isDeadlineAsc: boolean = false;
    public showTodoList: boolean = false;
    public taskIsChecked: boolean = false;
    public showFinishedTasks: boolean = false;
    public currentUser: User;
    public isAdmin: boolean;
    public ngbToday: NgbDate;
    public currentProfileId: number;

    constructor(
        private dashboardService: DashboardApiService,
        private router: Router,
        private toastrService: ToastrService,
        private modalService: ModalService,
        private authService: AuthenticationService,
        private profileDetailService: ProfileDetailsService,
        private activityService: ActivityApiService
    ) {}

    ngOnInit() {
        this.setCurrentProfileId();
        this.currentUser = this.authService.currentUserValue;
        this.isAdmin = this.currentUser.userRoleEnumDto == UserRoleEnum.Admin;
        this.ngbToday = convertToNgbDate(new Date());
        this.loadData();
    }

    public sort(field: string) {
        switch (field) {
            case "taskName":
                this.isTaskTypeNameAsc = !this.isTaskTypeNameAsc;
                if (!this.isTaskTypeNameAsc) {
                    this.tasks.sort(
                        this.byTextDescending(
                            (todoTask: TodoTask) => todoTask.taskName
                        )
                    );
                } else {
                    this.tasks.sort(
                        this.byTextAscending(
                            (todoTask: TodoTask) => todoTask.taskName
                        )
                    );
                }
                break;
            case "description":
                this.isDescriptionAsc = !this.isDescriptionAsc;
                if (!this.isDescriptionAsc) {
                    this.tasks.sort(
                        this.byTextDescending(
                            (todoTask: TodoTask) => todoTask.taskDescription
                        )
                    );
                } else {
                    this.tasks.sort(
                        this.byTextAscending(
                            (todoTask: TodoTask) => todoTask.taskDescription
                        )
                    );
                }
                break;
            case "consultantName":
                this.isConsultNameAsc = !this.isConsultNameAsc;
                if (!this.isConsultNameAsc) {
                    this.tasks.sort(
                        this.byTextDescending(
                            (todoTask: TodoTask) => todoTask.fullName
                        )
                    );
                } else {
                    this.tasks.sort(
                        this.byTextAscending(
                            (todoTask: TodoTask) => todoTask.fullName
                        )
                    );
                }
                break;
            case "date":
                this.isDateAsc = !this.isDateAsc;
                if (!this.isDateAsc) {
                    this.tasks.sort((a, b) =>
                        a.createdDate > b.createdDate ? -1 : 1
                    );
                } else {
                    this.tasks.sort((a, b) =>
                        a.createdDate < b.createdDate ? -1 : 1
                    );
                }
                break;
            case "deadline":
                this.isDeadlineAsc = !this.isDeadlineAsc;
                this.tasks.sort((a, b) => {
                    if (a.deadline === null || a.deadline === undefined) {
                        return 1;
                    } else if (b.deadline === null || b.deadline == undefined) {
                        return -1;
                    } else if (!this.isDeadlineAsc) {
                        return a.deadline > b.deadline ? -1 : 1;
                    } else {
                        return a.deadline < b.deadline ? -1 : 1;
                    }
                });
                break;
        }
    }

    byTextAscending =
        <T>(getTextProperty: (object: T) => string) =>
        (a: T, b: T) => {
            const upperA =
                getTextProperty(a) && getTextProperty(a).toUpperCase();
            const upperB =
                getTextProperty(b) && getTextProperty(b).toUpperCase();
            if (upperA < upperB) {
                return -1;
            }
            if (upperA > upperB) {
                return 1;
            }
            return 0;
        };

    byTextDescending =
        <T>(getTextProperty: (object: T) => string) =>
        (a: T, b: T) => {
            const upperA =
                getTextProperty(a) && getTextProperty(a).toUpperCase();
            const upperB =
                getTextProperty(b) && getTextProperty(b).toUpperCase();
            if (upperA < upperB) {
                return 1;
            }
            if (upperA > upperB) {
                return -1;
            }
            return 0;
        };

    //Events
    public taskClickEvent(task: TodoTask) {
        if (task.taskStatus != TodoTaskStatusEnum.Pending) {
            this.setTaskPending(task.id);
        }
        this.router.navigate(["/"], { skipLocationChange: true }).then(() => {
            this.router.navigate([task.getRouteLink()], {
                queryParams: { returnUrl: decodeURIComponent(this.router.url) },
            });
        });
    }

    public taskCheckChanged(task: TodoTask) {
        if (task.selected) {
            this.completeTask(task);
        } else {
            this.unCompleteTask(task);
        }
    }

    public priorityChanged(task: TodoTask, value: string) {
        if (task.taskStatus != TodoTaskStatusEnum.Pending) {
            var priority: number = parseInt(value);
            task.priority = priority;
            var taskDto = new TodoTasksDto();
            taskDto.createdDate = task.createdDate;
            taskDto.taskTypeId = task.taskTypeId;
            taskDto.priority = task.priority;
            taskDto.fullName = task.fullName;
            taskDto.id = task.id;
            taskDto.profileId = task.profileId;
            this.dashboardService
                .updatePriority(taskDto)
                .subscribe((result) => {
                    this.toastrService.success(
                        "Todo task prioritet er nu opdateret"
                    );
                });
        }
    }

    public updateDeadline(task: TodoTask) {
        task.deadline = convertToDate(task.ngbDeadline);
        var todoTask = new TodoTasksDto();
        todoTask.id = task.id;
        todoTask.deadline = task.deadline;
        if (task.activity) {
            todoTask.activity = new Activity();
            todoTask.activity.id = task.activity.id;
        }
        this.dashboardService.patchTask(todoTask).subscribe();
    }

    public fullNameClickEvent(task: TodoTask) {
        if (
            task.taskTypeId == TaskTypeEnum.AvailableDateChanged ||
            task.taskTypeId == TaskTypeEnum.ProfileLogAdded ||
            task.taskTypeId == TaskTypeEnum.Welcome
        ) {
            this.completeTask(task);
        }
        if (task.taskTypeId == TaskTypeEnum.OldCv) {
            this.setTaskPending(task.id);
        }
        this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
                this.router.navigate([task.getRouteLink()], {
                    queryParams: {
                        returnUrl: decodeURIComponent(this.router.url),
                    },
                });
            });
    }

    public flipShowNote(task: TodoTask) {
        task.showNote = !task.showNote;
        if (task.showNote == false) {
            this.setNoteEditMode(task, false);
        }
    }

    public setNoteEditMode(task: TodoTask, bool: boolean) {
        task.activity.noteEditMode = bool;
    }

    public saveNote(task: TodoTask) {
        var todoTaskDto = new TodoTasksDto();
        todoTaskDto.id = task.id;
        todoTaskDto.fullName = task.fullName;
        todoTaskDto.taskTypeId = task.taskTypeId;
        todoTaskDto.createdDate = task.createdDate;
        todoTaskDto.priority = task.priority;
        todoTaskDto.activity = task.activity;
        this.activityService.patch(todoTaskDto).subscribe((_) => {
            this.setNoteEditMode(task, false);
            this.toastrService.success("Note er blevet opdateret");
        });
    }

    public openActivityModal() {
        const options: NgbModalOptions = {
            centered: false,
            backdrop: true,
            size: "lg",
        };

        this.modalService.open(ActivitiesAddModalComponent, options);
    }

    public loadFinishedTodos() {
        var factory = new TodoTaskFactory();
        this.showFinishedTasks = !this.showFinishedTasks;
        if (this.showFinishedTasks && this.finishedTasksDto.length == 0) {
            this.dashboardService.getFinishedTasks().subscribe((result) => {
                this.finishedTasksDto = result;
                this.finishedTasksDto.forEach((element) => {
                    if (
                        element.taskTypeId != null ||
                        element.taskTypeId != undefined
                    ) {
                        var task = this.getFactoryTask(factory, element);
                        this.finishedTasks.push(task);
                    }
                });
            });
        }
    }

    private loadData() {
        var factory = new TodoTaskFactory();
        this.dashboardService.getTasks().subscribe((result) => {
            this.tasksDto = result;
            this.tasksDto.forEach((element) => {
                if (
                    element.taskTypeId != null ||
                    element.taskTypeId != undefined
                ) {
                    //Pushes the correct todotasks
                    var task = this.getFactoryTask(factory, element);
                    this.tasks.push(task);
                }
            });
        });
    }

    private getFactoryTask(factory: TodoTaskFactory, taskDto: TodoTasksDto) {
        var task = factory.createTodoTask(taskDto);
        task.createdDate = taskDto.createdDate;
        task.deadline = taskDto.deadline;
        task.priority = taskDto.priority;
        task.profileId = taskDto.profileId;
        task.taskStatus = taskDto.taskStatus;
        task.completedDate = taskDto.completedDate;
        task.ngbDeadline = convertToNgbDate(task.deadline);
        task.selected = false;
        return task;
    }

    private removeTask(taskId) {
        var index = this.tasks.findIndex((element, i) => {
            element.id == taskId;
        });
        this.tasks.splice(index);
    }

    private completeTask(task: TodoTask) {
        this.dashboardService.completeTask(task.id).subscribe();
    }

    private unCompleteTask(task: TodoTask) {
        this.dashboardService.unCompleteTask(task.id).subscribe();
    }

    private setTaskPending(id: number) {
        this.dashboardService.setTaskPending(id).subscribe((result) => {});
    }

    private setCurrentProfileId() {
        if (this.profileDetailService) {
            this.profileDetailService.getProfileIdObserver().subscribe((id) => {
                this.currentProfileId = id;
            });
        } else {
            this.currentProfileId = null;
        }
    }
}
