import { Location, NgIf } from '@angular/common';
import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TodoService } from 'src/app/core/services/todo.service';
import { UserApiService } from '../core/api/user-api.service';
import { LangService } from '../core/services/lang.service';
import { MainMenuComponent } from '../main-menu/components/main-menu/main-menu.component';
import { TodoListFullComponent } from '../dashboard/components/todo-list-full/todo-list-full.component';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-main-admin',
    templateUrl: './main-admin.component.html',
    styleUrls: ['./main-admin.component.scss'],
    standalone: true,
    imports: [RouterLink, FormsModule, NgIf, TodoListFullComponent, MainMenuComponent, RouterOutlet]
})

// @Injectable({
//   providedIn: 'root'
// })
export class MainAdminComponent implements OnInit {
  public isTodoShown: boolean = false;

  public currentLang;

  constructor(
    private todoService: TodoService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
    private langService: LangService,
    private userApiService: UserApiService
  ) {
    this.currentLang = this.langService.getSavedLang();
  }
  public useLang(lang: string) {
    this.langService.changeLang(lang);
    this.userApiService.patchLanguage(lang).subscribe();
  }

  ngOnInit() {
    this.activatedRoute.fragment.subscribe(fragment => {
      this.handleTodoFragment(fragment);
    })
    this.todoService.setTodosVisibility$.subscribe(x => {
      let path = this.location.path(false);
      if (!x) {
        this.location.replaceState(path);
      }
      else {
        this.activatedRoute.fragment.subscribe(fragment => {
          if (fragment !== "todo") {
            this.location.replaceState(path + "#todo");
          }
        })
      }
      this.isTodoShown = x;
    });
  }

  public toggleTodoList(value: boolean) {
    this.todoService.toggleTodoList(value);
  }

  private handleTodoFragment(fragment: string) {
    if (fragment === "todo") {
      this.toggleTodoList(true);
    }
    else {
      this.toggleTodoList(false);
    }
  }
}
